<template>
  <b-row>
    <b-col cols="12"><b-overlay
      :show="$apollo.loading"
      spinner-variant="primary"
    >
      <b-card title="All Programs">

        <!-- search input -->
        <div class="custom-search d-flex justify-content-start">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                class="d-inline-block mr-1"
                placeholder="Search"
                type="text"
              />
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :to="{name: 'add-programs'}"
                class="d-inline-flex mr-1"
                variant="outline-success"
              >
                <feather-icon
                  class="mr-50"
                  icon="PlusIcon"
                />
                <span class="align-middle text-nowrap">Add Program</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="d-inline-flex mr-1"
                variant="outline-primary"
              >
                <feather-icon
                  class="mr-50"
                  icon="PlusIcon"
                />
                <span class="align-middle text-nowrap">Assign Team</span>
              </b-button>
            </div>
          </b-form-group>
        </div>
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'fullName'"
              class="text-nowrap"
            >
              <b-avatar
                :src="props.row.avatar"
                class="mx-1"
              />
              <span class="text-nowrap">{{ props.row.fullName }}</span>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.status)">
                {{ props.row.status }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  dropright
                  no-caret
                  size="sm"
                  toggle-class="text-decoration-none"
                  variant="link"
                >
                  <template v-slot:button-content>
                    <feather-icon
                      class="text-body align-middle"
                      icon="MoreVerticalIcon"
                      size="16"
                    />
                  </template>
                  <b-dropdown-item :to="`/programs/manager/${props.row.id}`">
                    <feather-icon
                      class="mr-50"
                      icon="EyeIcon"
                    />
                    <span>Open Program Manager</span>
                  </b-dropdown-item>
                  <b-dropdown-item :to="`/programs/add/${props.row.id}`">
                    <feather-icon
                      class="mr-50"
                      icon="FileIcon"
                    />
                    <span>View Program Model</span>
                  </b-dropdown-item>
                  <b-dropdown-item variant="danger">
                    <feather-icon
                      class="mr-50"
                      icon="PauseIcon"
                      style="fill:currentColor"
                    />
                    <span>Discontinue Program</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :per-page="pageLength"
                  :total-rows="props.total"
                  :value="1"
                  align="right"
                  class="mt-1 mb-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card></b-overlay>
    </b-col>
    <!-- Ind/Limited Res. modal -->
    <b-modal
      id="unitResource"
      hide-header-close
      ok-only
      title="Basic Modal"
    >
      <b-card-text>
        <i>Total Units Available:</i> 12<br>
        <i>Total Units Availed by Startups:</i> 3 <br>
        <span class="font-medium-2 text-primary">Grand Total: 15 <br></span>
      </b-card-text>
    </b-modal>
    <b-modal
      id="sharedResource"
      hide-header-close
      ok-only
      title="Basic Modal"
    >
      <b-card-text>
        Shared Resource Booking.
      </b-card-text>
    </b-modal>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BModal,
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown, BDropdownItem, BButton, BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import gql from 'graphql-tag'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Participants',
          field(row) {
            if (row) return '-'
            return '-'
          },
        },
        {
          label: 'Partners',
          field(row) {
            if (!row.programs_partnerstables.length) return '-'
            return row.programs_partnerstables.map(e => e.networks_basicinfo?.name || '')
              .join(', ')
          },
        },
        {
          label: 'Progress',
          field(row) {
            if (row) return '-'
            return '-'
          },
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  apollo: {
    rows: {
      query: gql`
        {
        programs_basicinfo(order_by: {date_created: desc}) {
          id,
          title,
          status,
          programs_partnerstables {
            networks_basicinfo {
              name
            }
          }
        }
      }`,
      update: data => data.programs_basicinfo,
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
